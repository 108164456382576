import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowForwardIos, Check, Close } from '@mui/icons-material';
import { HorizontalProductCard } from './HorizontalProductCard';
import { ConfirmOrder } from './ConfirmOrder';
import { toCurrencyFormat } from '../../../../utils/formatter';
import { useAppSelector } from 'redux/hook';
import { ShopCartItem } from 'types/ShopCart';
import chaletApi from 'api/chaletApi';
import { CartDrawer } from './CartDrawer';
import { DeliveryData } from 'types/DeliveryData';
import { StepperBox } from './style';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import routesConstants from 'utils/routesConstants';

const steps = [1, 2];

type CartProps = {
  open: boolean;
  handleStateViewDrawer: () => void;
  shopId: number;
};

export const Cart: React.FC<CartProps> = ({
  open,
  handleStateViewDrawer,
  shopId,
}) => {
  const { shopCarts } = useAppSelector((state) => state.cartSlice);
  const { userId, isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const [createOrder] = chaletApi.useCreateOrderMutation();
  const [getUser, user] = chaletApi.useLazyGetUserByIdQuery();

  const [lastLocationId, setLastLocationId] = React.useState<
    number | undefined
  >(undefined);
  const [total, setTotal] = useState<number>(0);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [currentShop, setCurrentShop] = useState<ShopCartItem | null>(null);
  const [deliveryData, setDeliveryData] = useState<DeliveryData | null>(null);
  const [openAlert, setOpenAlert] = React.useState(true);
  const maxSteps = steps.length;

  const navigate = useNavigate();

  const calculateTotal = () => {
    if (currentShop) {
      const total = currentShop.products.reduce((acc, product) => {
        return acc + product.total;
      }, 0);

      setTotal(total);
    } else {
      setTotal(0);
    }
  };

  useEffect(() => {
    const cartIndex = shopCarts.findIndex(
      (cartItem) => cartItem.shopId === shopId,
    );
    if (cartIndex !== -1) {
      setCurrentShop(shopCarts[cartIndex]);
      calculateTotal();
    } else {
      setCurrentShop(null);
    }
  }, [shopCarts, shopId, calculateTotal]);

  useEffect(() => {
    if (userId) {
      getUser(userId)
        .unwrap()
        .then((payload) => {
          const lastLocationId = payload.config.lastLocationId;

          setLastLocationId(lastLocationId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userId, isLoggedIn]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeliveryData = (data: DeliveryData) => {
    setDeliveryData(data);
  };

  const handlePostEvent = async () => {
    try {
      if (currentShop && deliveryData && userId && lastLocationId) {
        const orderDetails = currentShop?.products.map((product) => {
          const optionsArray = Object.values(product.options);

          const variations = optionsArray.map((option) => ({
            variationOptionId: option.id,
            increase: option.addedValue,
          }));

          return {
            quantity: product.quantity,
            name: product.name,
            productId: product.productId,
            productPrice: product.price,
            variations: variations,
            notes: product.notes,
          };
        });

        const OrderData = {
          shopId: currentShop.shopId,
          userId: userId,
          addressId: lastLocationId,
          details: orderDetails,
          orderProcessId: deliveryData.orderProcessId,
          currentOrderProcessStepId: deliveryData.currentOrderProcessStepId,
          deliveryPrice: deliveryData.deliveryPrice,
          deliveryType: deliveryData.deliveryType,
        };

        toast.promise(
          createOrder(OrderData)
            .unwrap()
            .then(() => {
              navigate(routesConstants.orders);
            })
            .catch((error) => console.error('rejected', error)),
          {
            loading: 'Guardando...',
            success: <b>Orden Creada</b>,
            error: <b>Ha ocurrido un error</b>,
          },
        );
      }
    } catch (error) {
      console.error('Error en la acción POST:', error);
    }
  };

  return (
    <CartDrawer
      open={open}
      handleStateViewDrawer={handleStateViewDrawer}
      activeStep={activeStep}
      maxSteps={maxSteps}
      back={handleBack}
    >
      {currentShop ? (
        <Box>
          <Box>
            {activeStep === steps.length ? (
              <Box>
                <Typography variant="h5">¡Orden confirmada!</Typography>
              </Box>
            ) : (
              <Box>
                {activeStep === 0 && (
                  <>
                    <Box sx={{ marginBottom: { xs: '75px', sm: '0px' } }}>
                      <HorizontalProductCard currentShop={currentShop} />
                    </Box>
                    <StepperBox>
                      <Box flex={1}>
                        <Stack direction={'column'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography
                              flex={1}
                              sx={{ fontSize: 20, fontWeight: 'bold', mt: 1 }}
                            >
                              Total:
                            </Typography>
                            <Typography sx={{ fontSize: 20, mt: 1 }}>
                              {toCurrencyFormat(total)}
                            </Typography>
                          </Box>

                          <Button
                            endIcon={<ArrowForwardIos />}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            fullWidth
                          >
                            Siguiente
                          </Button>
                        </Stack>
                      </Box>
                    </StepperBox>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    {!isLoggedIn && (
                      <Box sx={{ width: '100%' }}>
                        <Collapse in={openAlert}>
                          <Alert
                            severity="warning"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setOpenAlert(false);
                                }}
                              >
                                <Close fontSize="inherit" />
                              </IconButton>
                            }
                            sx={{ mb: 2 }}
                          >
                            Debes iniciar sesión para poder realizar un pedido
                          </Alert>
                        </Collapse>
                      </Box>
                    )}
                    <Box>
                      <ConfirmOrder
                        currentShop={currentShop}
                        total={total}
                        setDeliveryData={handleDeliveryData}
                      ></ConfirmOrder>
                    </Box>

                    <StepperBox>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePostEvent}
                        fullWidth
                        disabled={
                          !deliveryData || !isLoggedIn || !lastLocationId
                        }
                        startIcon={<Check />}
                      >
                        Confirmar Orden
                      </Button>
                    </StepperBox>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="body2">
          Tu carrito está vacío para esta tienda.
        </Typography>
      )}
    </CartDrawer>
  );
};
