import { Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './ui/components/common/footer/Footer';
import { PrivacyPolicy } from './ui/pages/privacy_policy';
import { Appbar } from './ui/components/common/appbar/Appbar';
import { Orders } from './ui/pages/orders';
import { NearShop } from './ui/pages/NearShop';
import { Shop } from './ui/pages/Shop';
import { Product } from './ui/pages/Product';
import { Home } from './ui/pages/home';
import { Login } from './ui/pages/Auth/login';
import { Register } from './ui/pages/Auth/register';
import { AboutUs } from 'ui/pages/profile/AboutUs';
import { FrequentQuestions } from 'ui/pages/profile/FrequentQuestions';
import { ClientService } from 'ui/pages/profile/ClientService';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import chaletApi from 'api/chaletApi';
import { useEffect } from 'react';
import { addLocation } from 'redux/slices/locationSlice';
import { ShopRegisterForm } from 'ui/pages/ShopRegisterForm/ShopRegisterForm';
import { Profile, EditProfile } from 'ui/pages/profile';
import DeleteAccount from 'ui/pages/DeleteAccount/DeleteAccount';
import routesConstants from 'utils/routesConstants';

const AppRouter = () => {
  const location = useLocation();
  let hideAppbarComponent =
    location.pathname.includes('/shop/') ||
    location.pathname.includes('/product/') ||
    location.pathname === '/'

  const hideFooterComponent =
    location.pathname.includes('/shop/') ||
    location.pathname.includes('/product/')

  console.log(location.pathname)
  const { isLoggedIn, userId } = useAppSelector((state) => state.userAuthSlice);
  const [getUserAddress, result] = chaletApi.useLazyGetUserAddressQuery();
  const [getUser, user] = chaletApi.useLazyGetUserByIdQuery();
  const [getOneUserAddress, address] =
    chaletApi.useLazyGetOneUserAddressQuery();
  if (isLoggedIn && location.pathname === '/') {
    hideAppbarComponent = false;
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn && userId) {
      getUser(userId)
        .unwrap()
        .then((payload) => {
          const lastLocationId = payload.config.lastLocationId;
          console.log(lastLocationId);
          if (lastLocationId) {
            getOneUserAddress(lastLocationId)
              .unwrap()
              .then((payload) => {
                console.log('payload', payload);
                const updateLocation = {
                  id: payload.id,
                  name: payload.name,
                  address: payload.address,
                  reference: payload.reference,
                  location: payload.location,
                };

                dispatch(addLocation(updateLocation));
              })
              .catch((error) => console.error('rejected 2', error));
          }
        })
        .catch((error) => console.error('rejected 1', error));
    }
  }, [isLoggedIn, userId]);

  return (
    <>
      <Appbar hideComponents={hideAppbarComponent}>
        <Routes>
          <Route path={routesConstants.initial} element={<Home />} />
          <Route path={routesConstants.orders} element={<Orders />} />
          <Route path={routesConstants.home} element={<NearShop />} />
          <Route path={routesConstants.shopDef} element={<Shop />} />
          <Route
            path={routesConstants.shopProductDef}
            element={<Product />}
          />
          <Route path={routesConstants.login} element={<Login />} />
          <Route path={routesConstants.profile} element={<Profile />} />
          <Route path={routesConstants.aboutUs} element={<AboutUs />} />
          <Route path={routesConstants.signUp} element={<Register />} />
          <Route path={routesConstants.faq} element={<FrequentQuestions />} />
          <Route path={routesConstants.customerService} element={<ClientService />} />
          {/* <Route path={routesConstants} element={<GenerateRoute />} /> */}
          <Route path={routesConstants.termsAndConditions} element={<PrivacyPolicy />} />
          <Route path={routesConstants.shopSignUp} element={<ShopRegisterForm />} />
          <Route path={routesConstants.deleteAccount} element={<DeleteAccount />} />
          <Route path="/editar_perfil" element={<EditProfile />} />
          <Route path="*" element={<div>Not found</div>} />
        </Routes>
      </Appbar>
      {!hideFooterComponent && <Footer />}
    </>
  );
};

export default AppRouter;
