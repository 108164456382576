import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Container } from '@mui/material';

import {
  RestaurantOutlined,
  ShoppingBagOutlined,
  StorefrontOutlined,
} from '@mui/icons-material';

import { NearShopsList } from 'ui/components/partials/NearShops/NearShopsList';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

export const NearShop = () => {
  const [type, setType] = React.useState<string>('food');
  const [selectedCategory, setSelectedCategory] = useState('Todos');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setType(newValue);
    setSelectedCategory('Todos');
  };

  const toggleSelectedCategory = (selectedCategory: string) => {
    setSelectedCategory(selectedCategory);
  };

  return (
    <Container maxWidth="md">
      <Box
        display={'flex'}
        justifyContent={'center'}
        sx={{
          maxWidth: { xs: '100vw', sm: '100%' },
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={type}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
        >
          <Tab
            icon={<RestaurantOutlined />}
            iconPosition="start"
            label="Restaurantes"
            value="food"
          />
          <Tab
            icon={<StorefrontOutlined />}
            iconPosition={'start'}
            label="Supermercados"
            value="market"
          />
          <Tab
            icon={<ShoppingBagOutlined />}
            iconPosition={'start'}
            label="tiendas"
            value="shopping"
          />
        </Tabs>
      </Box>
      <TabPanel index={type} value={type}>
        <NearShopsList
          type={type}
          toggleSelectedCategory={toggleSelectedCategory}
          selectedCategory={selectedCategory}
        />
      </TabPanel>
    </Container>
  );
};
