import { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Paper,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Modal,
  TextField,
  styled,
  useTheme,
  Box,
  IconButton,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import chaletApi from 'api/chaletApi';
import { openEditUserModal, closeEditUserModal } from 'redux/slices/modalSlice';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { pictureValidation } from 'utils/pictureValidation';
import { CloudUpload } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const schema = yup.object().shape({
  name: yup.string().required('El nombre es obligatorio'),
  username: yup.string().required('El nombre de usuario es obligatorio'),
  email: yup
    .string()
    .email('Correo electrónico no válido')
    .required('El correo electrónico es obligatorio'),
});

export const EditProfile = () => {
  const dispatch = useAppDispatch();
  const { isEditUserModalOpen } = useAppSelector((state) => state.modalSlice);
  const { user } = useAppSelector((state) => state.user);
  const [updateUser, result] = chaletApi.useUpdateUserMutation();

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [previewProfileImage, setPreviewProfileImage] = useState<string | null>(
    null,
  );

  const handleClose = useCallback(() => {
    dispatch(closeEditUserModal());
  }, [dispatch]);

  const theme = useTheme();

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeProfileImage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      pictureValidation(file, setProfileImage, setPreviewProfileImage);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        username: user.username || '',
        email: user.email || '',
      });
    }
  }, [user]);

  useEffect(() => {
    if (!isEditUserModalOpen && result.isError) {
      toast('Error guardando usuario');
    }

    if (!isEditUserModalOpen && result.isSuccess) {
      handleClose();
    }
  }, [result, handleClose, isEditUserModalOpen]);

  const saveUser = async (data: any) => {
    const userId = user.id;
    const { name, username, email } = data;

    if (profileImage) {
      const saveFormData = new FormData();

      saveFormData.append('name', name);
      saveFormData.append('username', username);
      saveFormData.append('email', email);
      saveFormData.append('profileImage', profileImage);

      await updateUser({ userId, body: saveFormData })
        .unwrap()
        .then((payload: any) => {
          console.log(payload);
          if (payload.success) {
            toast.success('Usuario actualizado');
            handleClose();
          } else {
            toast.error('ha ocurrido un error, verifica tus datos');
          }
        })
        .catch((error: any) => {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        });
    } else {
      await updateUser({ userId, body: data })
        .unwrap()
        .then((payload: any) => {
          if (payload.success) {
            toast.success('Usuario actualizado');
            handleClose();
          } else {
            toast.error('ha ocurrido un error, verifica tus datos');
          }
        })
        .catch((error: any) => {
          toast.error('Ha ocurrido un error, intenta nuevamente');
        });
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="80vh">
      <Grid item xs={12} md={8} lg={6}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            textAlign: 'center',
            borderRadius: '12px',
            margin: 'auto',
            maxWidth: '400px',
          }}
        >
          <Avatar
            src={user?.profileImage}
            alt={user?.name}
            sx={{
              width: '120px',
              height: '120px',
              marginBottom: '10px',
              margin: 'auto',
            }}
          />
          <Typography variant="h4" marginTop={'5%'}>
            {user?.name}
          </Typography>
          <Table sx={{ width: '100%' }}>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Usuario:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.username}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Email:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.email}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle1">
                    <strong>Teléfono:</strong>
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle1">{user?.phone}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            sx={{
              mt: 2,
            }}
            variant="contained"
            color="primary"
            onClick={() => dispatch(openEditUserModal())}
          >
            Actualizar Perfil
          </Button>
          <Modal open={isEditUserModalOpen} onClose={handleClose}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 8,
                textAlign: 'center',
              }}
            >
              <form onSubmit={handleSubmit(saveUser)}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                  Actualizar Perfil
                </Typography>

                <Avatar
                  sx={{
                    border: '4px solid #ffffff',
                    width: '125px',
                    height: '125px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  src={
                    previewProfileImage
                      ? previewProfileImage
                      : user?.profileImage
                  }
                />
                <IconButton
                  sx={{
                    marginTop: '-50%',
                    backgroundColor: theme.palette.white.main,
                    '&:hover': {
                      backgroundColor: `rgba(128, 128, 128, 0.7)`,
                    },
                  }}
                  component="label"
                  color="primary"
                >
                  <CloudUpload />
                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={handleChangeProfileImage}
                  />
                </IconButton>
                <TextField
                  label="Nombre"
                  fullWidth
                  {...register('name')}
                  defaultValue={formData.name}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Nombre de Usuario"
                  fullWidth
                  {...register('username')}
                  defaultValue={formData.username}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  sx={{ marginBottom: 2 }}
                />
                <TextField
                  label="Correo Electrónico"
                  fullWidth
                  {...register('email')}
                  defaultValue={formData.email}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  sx={{ marginBottom: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2, marginRight: 2 }}
                >
                  Guardar Cambios
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ marginTop: 2 }}
                >
                  Cerrar
                </Button>
              </form>
            </Box>
          </Modal>
        </Paper>
      </Grid>
    </Grid>
  );
};
