import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@mui/material';

interface Page {
  name: string;
  route: string;
  icon: JSX.Element;
}

interface BottomBarProps {
  pages: Page[];
}

export const BottomBar: React.FC<BottomBarProps> = ({ pages }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);
  if (!pages) {
    return null;
  }

  const onBottomChange = (event: React.SyntheticEvent, newValue: number) => {
    const page = pages[newValue];
    navigate(page.route, { replace: false });
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        zIndex: 99,
        display: { xs: 'flex', md: 'none' },
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <Box flex={1}>
        <BottomNavigation showLabels value={value} onChange={onBottomChange}>
          {pages.map(({ name, icon }, index) => (
            <BottomNavigationAction key={index} label={name} icon={icon} />
          ))}
        </BottomNavigation>
      </Box>
    </Paper>
  );
};
