import { DeliveryDining } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { NearShop } from 'api/chaletApi';
import React from 'react';
import { formatTime } from 'utils/formatter';
import { getStoreStatusCode, isStoreOpen } from 'utils/store';

type nearShopProps = {
  shop: NearShop;
  OpenShop: (id: number) => void;
};

export const NearShopsCard: React.FC<nearShopProps> = ({ shop, OpenShop }) => {
  return (
    <Card
      elevation={0}
      key={shop.id}
      sx={{
        margin: { xs: 0, sm: 2 },
        width: { sm: '400px' },
        cursor: 'pointer',
        ':hover': {
          boxShadow: '0 0 0 1px #fff, 0 0 0 3px #000',
        },
      }}
      onClick={() => OpenShop(shop.id)}
    >
      <CardMedia
        sx={{
          height: { xs: 180, sm: 180 },
          borderRadius: '5px',
          margin: 1,
        }}
        image={shop.coverImage}
        title="cover"
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              variant="square"
              src={shop.profileImage}
              sx={{ width: 80, height: 80 }}
            />
          </Grid>
          <Grid item xs={8} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="h6" fontSize={18}>
                  {shop.name}
                </Typography>
                {shop.isClosedToday ? (
                  <Typography>CERRADO</Typography>
                ) : (
                  <>
                    {getStoreStatusCode(shop) === 1 ? (
                      // Caso cuando la tienda está cerrada
                      <Typography>CERRADO</Typography>
                    ) : getStoreStatusCode(shop) === 0 ? (
                      // Caso cuando la tienda aún no ha abierto
                      <Box display={'flex'} alignItems={'center'}>
                        <DeliveryDining
                          sx={{
                            marginRight: '2px',
                            display: 'flex',
                          }}
                        />
                        <Typography variant="body2">
                          Abre a las {formatTime(shop.opensAt)}
                        </Typography>
                      </Box>
                    ) : (
                      // Caso cuando la tienda está abierta
                      <Box display={'flex'} alignItems={'center'}>
                        <DeliveryDining
                          sx={{
                            marginRight: '2px',
                            display: 'flex',
                          }}
                        />
                        <Typography variant="body2">
                          {shop.estimatedOrderTime} -{' '}
                          {shop.estimatedOrderTime + 15} min
                        </Typography>
                      </Box>
                    )}
                  </>
                )}

                {/*        <Box
              sx={{
                display: 'flex',
                maxWidth: { xs: '230px', sm: '700px' },
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  height: '6px',
                  display: 'none', // Oculta la barra en dispositivos móviles (xs)
                },
                '@media (min-width: 600px)': {
                  // Muestra la barra en tamaños de pantalla mayores o iguales a 600px (sm)
                  '&::-webkit-scrollbar': {
                    display: 'block',
                  },
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '3px',
                },
              }}
            >
              {shop.categories.map(
                (category: {
                  id: number;
                  name: string;
                }) => (
                  <Chip
                    key={category.id}
                    sx={{ mr: 0.5, fontSize: '12px' }}
                    label={category.name}
                  />
                ),
              )}
            </Box> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider></Divider>
    </Card>
  );
};
