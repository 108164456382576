const routesConstants = {
  initial: '/',
  home: '/home',
  orders: '/orders',
  shop: (shopId: string | undefined) => `/shop/${shopId}`,
  shopDef: `/shop/:shopId`,
  shopProductDef: '/shop/:shopId/product/:productId',
  shopProduct: (shopId: number, productId: number) => `/shop/${shopId}/product/${productId}`,
  login: '/login',
  signUp: '/sign-up',
  profile: '/profile',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  faq: '/faq',
  customerService: '/customer-service',
  termsAndConditions: '/terms-and-conditions',
  shopSignUp: '/shop-sign-up',
  deleteAccount: '/delete-account',
};


export default routesConstants;