import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import { toCurrencyFormat } from '../../../../../utils/formatter';
import { useDispatch } from 'react-redux';
import { removeFromCart } from '../../../../../redux/slices/cartSlice';
import { Delete, Remove } from '@mui/icons-material';
import { ProductVariationView } from '../ProductVariationView';
import { CustomBox } from '../style';
import { useAppDispatch } from 'redux/hook';
import { ShopCartItem } from 'types/ShopCart';

type HorizontalProductCardProps = {
  currentShop: ShopCartItem;
};

export const HorizontalProductCard: React.FC<HorizontalProductCardProps> = ({
  currentShop,
}) => {
  const dispatch = useAppDispatch();

  const handleRemoveFromCart = (uniqueProductId: number) => {
    dispatch(
      removeFromCart({
        shopId: currentShop.shopId,
        uniqueProductId: uniqueProductId,
      }),
    );
  };

  return currentShop && currentShop.products.length > 0 ? (
    currentShop.products.map((item) => {
      return (
        <Box key={item.uniqueProductId}>
          <CustomBox>
            <Box display={'flex'}>
              {item.image && (
                <Box mr={1}>
                  <img
                    width={100}
                    height={100}
                    src={item.image}
                    style={{
                      borderRadius: '5px',
                      aspectRatio: '1/1',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              )}

              <Box display={'flex'} flexDirection={'column'} flex={1}>
                <Box>
                  <Typography fontSize={16}>
                    {item.name} X{item.quantity}
                  </Typography>
                </Box>
                <Box>
                  <ProductVariationView product={item} />
                </Box>

                {item.notes !== '' && (
                  <Box>
                    <Typography sx={{ fontSize: 12 }} color={'text.secondary'}>
                      {item.notes}
                    </Typography>
                  </Box>
                )}

                <Box id="caja" alignSelf={'self-end'}>
                  <Stack direction={'row'} spacing={1} mt={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleRemoveFromCart(item.uniqueProductId)}
                    >
                      <Remove />
                    </Button>
                    <Typography fontSize={18}>
                      {toCurrencyFormat(item.total)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </CustomBox>
          <Divider sx={{ mb: 1, mt: 1 }}></Divider>
        </Box>
      );
    })
  ) : (
    <Typography variant="body1" color="textSecondary" textAlign="center">
      No se encontraron ordenes para esta tienda.
    </Typography>
  );
};
