import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React from 'react';
import { OrderTicket } from '../OrderTicket';
import { CloseRounded } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { DeliveryMap } from '../delivery_map';
import { OrderCard } from '../OrderCard';

const CustomBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: 900,
  backgroundColor: 'white',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 15,
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    fontSize: '1rem',
    boxSizing: 'border-box',
  },
}));

type OrderModalProps = {
  open: boolean;
  handleClose: () => void;
  orderId: number;
};

export const OrderModal: React.FC<OrderModalProps> = ({
  open,
  handleClose,
  orderId,
}) => {
  const { data, isLoading, isError } = chaletApi.useGetOneOrderQuery(orderId);
  const [cancelOrder] = chaletApi.useCancelOrderMutation();
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const [cancelReason, setCancelReason] = React.useState<string>('');

  if (isError) {
    return (
      <p>
        Error: No se pudo cargar los datos. Por favor, inténtalo de nuevo más
        tarde.
      </p>
    );
  }

  if (data) {
    const ValidateCancelOrder = () => {
      if (data?.currentOrderProcessStep.correlative === 1) {
        setShowDelete(true);
      } else {
        toast.error('La orden ya esta en proceso, no se puede cancelar');
      }
    };

    const CancelOrder = () => {
      const CancelOrderBody = {
        orderId: data.id,
        reason: cancelReason,
      };
      cancelOrder(CancelOrderBody);
    };

    const handleCancelReason = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCancelReason(event.target.value);
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" style={{ fontStyle: 'bold' }}>
              Detalle de la orden
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>

          <DeliveryMap order={data} />

          <OrderCard order={data} />

          <Box
            display={showDelete ? 'none' : 'flex'}
            justifyContent={'center'}
            mt={2}
          >
            <Button
              variant="contained"
              color="error"
              onClick={ValidateCancelOrder}
              disabled={showDelete || data.isCanceled === true}
            >
              Cancelar Orden
            </Button>
          </Box>

          {showDelete && (
            <Box
              component={'form'}
              display={'flex'}
              justifyContent={'center'}
              flexDirection={'column'}
              mt={2}
              onSubmit={CancelOrder}
            >
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="¿Por qué quieres cancelar?"
                name="cancelReason"
                multiline
                rows={3}
                required
                onChange={handleCancelReason}
              />

              <Button
                type="submit"
                sx={{ mt: 1 }}
                variant="contained"
                color="error"
              >
                Confirmar
              </Button>
            </Box>
          )}
        </CustomBox>
      </Modal>
    );
  }
};
