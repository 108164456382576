import { Box, Typography } from '@mui/material';
import React from 'react';
import { toCurrencyFormat } from '../../../../../utils/formatter';
import { Product, VariationOption } from 'types/ShopCart';

type ProductVariationViewProps = {
  product: Product;
};

export const ProductVariationView: React.FC<ProductVariationViewProps> = ({
  product,
}) => {
  const formatOption = (option: VariationOption) => {
    return option.addedValue === 0
      ? `${option.value}`
      : `${option.value} +(${toCurrencyFormat(option.addedValue)})`;
    /* `${option.value} (${toCurrencyFormat(option.addedValue)})`; */
  };

  const formattedVariations = product.variations.map((variation) => {
    const options = variation.options
      .filter((option) => product.options[option.id.toString()])
      .map(formatOption)
      .join(', ');

    return (
      <div key={variation.id}>
        <Typography sx={{ fontSize: 12 }} color={'text.secondary'}>
          {variation.name}: {options}
        </Typography>
      </div>
    );
  });

  return <Box>{formattedVariations}</Box>;
};
