import { ArrowBackIos, Close, CloseRounded } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import React, { Children } from 'react';

const CustomBox = styled(Box)(({ theme }) => ({
  width: '500px',
  padding: 15,
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
    boxSizing: 'border-box',
  },
}));

type CartDrawerProps = {
  open: boolean;
  handleStateViewDrawer: () => void;
  children: React.ReactNode;
  activeStep: number;
  maxSteps: number;
  back: () => void;
};

export const CartDrawer: React.FC<CartDrawerProps> = ({
  open,
  handleStateViewDrawer,
  children,
  activeStep,
  maxSteps,
  back,
}) => {
  const handleBack = () => {
    if (activeStep === 1) {
      back();
    } else {
      handleStateViewDrawer();
    }
  };

  return (
    <Drawer anchor={'right'} open={open}>
      <CustomBox>
        <Box>
          <Box>
            <Button
              color="primary"
              onClick={handleBack}
              startIcon={<ArrowBackIos />}
            >
              Atrás
            </Button>
          </Box>

          <Box display="flex" justifyContent={'center'} sx={{ mt: '-30px' }}>
            <Typography fontWeight={'bold'}>
              {activeStep + 1}/{maxSteps}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1.5 }} />

        {children}
      </CustomBox>
    </Drawer>
  );
};
