import { createSlice } from '@reduxjs/toolkit';
import chaletApi from '../../api/chaletApi';
import { LocalStorage } from '../../utils/localStorage';

type AuthState = {
  isLoggedIn: boolean;
  userId: string | null;
  token: string | null;
};

const initialState: AuthState = {
  isLoggedIn: false,
  userId: LocalStorage.getStringWithIdentifier(LocalStorage.keys.userId),
  token: LocalStorage.getStringWithIdentifier(LocalStorage.keys.jwtToken),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      LocalStorage.removeItemWithIdentifier(LocalStorage.keys.userId);
      LocalStorage.removeItemWithIdentifier(LocalStorage.keys.jwtToken);
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chaletApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.success) {
          state.isLoggedIn = true;
          state.token = payload.data.token;
          state.userId = payload.data.user.id.toString();
          LocalStorage.setStringWithIdentifier(
            LocalStorage.keys.jwtToken,
            payload.data.token,
          );
          LocalStorage.setStringWithIdentifier(
            LocalStorage.keys.userId,
            payload.data.user.id.toString(),
          );
        }
      },
    );
  },
});

export const { logOut } = authSlice.actions;

export default authSlice;
