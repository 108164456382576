import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  CircularProgress,
  styled,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';

import chaletApi from '../../../../api/chaletApi';
import { AddressCard } from './AddressCard/AddressCard';
import { AddUserAddress } from './AddUserAddress/AddUserAddress';
import { Add, ArrowBackIos, Close } from '@mui/icons-material';
import { useAppSelector } from 'redux/hook';
import { address } from 'types/Address';
import { User } from 'types/User';

const BoxStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.5em',
  boxShadow: '0px 0px 50px 5px rgba(0, 0, 0, 0.5)',
  padding: 15,
  width: 500,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  width: 500,
  height: 800,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  overflowY: 'scroll',
  padding: 15,
  borderRadius: '5px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
}));

type LocationsModalProps = {
  open: boolean;
  handleClose: () => void;
};

export const LocationsModal: React.FC<LocationsModalProps> = ({
  open,
  handleClose,
}) => {
  const [showAddAddress, setShowAddAddress] = React.useState<boolean>(false);
  const [showEditAddress, setShowEditAddress] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [lastLocationId, setLastLocationId] = React.useState<number>(0);
  const [selectedAddress, setSelectedAddress] = React.useState<
    address | undefined
  >(undefined);

  const { isLoggedIn, userId } = useAppSelector((state) => state.userAuthSlice);
  const [getUserAddress, result] = chaletApi.useLazyGetUserAddressQuery();
  const [getUser, user] = chaletApi.useLazyGetUserByIdQuery();

  useEffect(() => {
    if (isLoggedIn && userId) {
      getUserAddress({});
      getUser(userId);
    }
  }, [isLoggedIn, lastLocationId]);

  useEffect(() => {
    if (user.data) {
      const lastLocationId: number = user.data.config.lastLocationId;
      setLastLocationId(lastLocationId);
    }
  }, [user.data]);

  if (result.isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBackController = () => {
    if (showAddAddress === true || showEditAddress === true) {
      if (activeStep === 1) {
        handleBack();
      } else {
        setShowAddAddress(false);
        setShowEditAddress(false);
      }
    } else {
      handleClose();
    }
  };

  if (result.data && user.data) {
    const addresses: address[] = result.data.data;

    const sortedAddresses = addresses.slice().sort((a, b) => {
      if (a.id === lastLocationId) return -1;
      if (b.id === lastLocationId) return 1;
      return 0;
    });

    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CustomBox>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="primary"
                  onClick={handleBackController}
                  startIcon={<ArrowBackIos />}
                >
                  Atrás
                </Button>
                <Typography variant="h6" style={{ fontStyle: 'bold' }}>
                  Mis direcciones
                </Typography>

                {!showAddAddress && !showEditAddress && (
                  <IconButton
                    color="primary"
                    onClick={() => setShowAddAddress(true)}
                  >
                    <Add fontSize="large" />
                  </IconButton>
                )}
              </Stack>
            </Box>
            {showAddAddress || showEditAddress ? (
              <AddUserAddress
                address={showEditAddress ? selectedAddress : undefined}
                onSubmit={() => {
                  setShowAddAddress(false);
                  setShowEditAddress(false);
                  setActiveStep(0);
                }}
                isLoggedIn={isLoggedIn}
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBackController}
              />
            ) : (
              <>
                {sortedAddresses.map((address) => (
                  <AddressCard
                    key={address.id}
                    address={address}
                    lastLocationId={lastLocationId}
                    handleClose={handleClose}
                    onUpdateLastLocationId={(newId: number) =>
                      setLastLocationId(newId)
                    }
                    onEditClick={() => {
                      setSelectedAddress(address);
                      setShowEditAddress(true);
                    }}
                  />
                ))}
              </>
            )}
          </CustomBox>
        </Modal>
      </>
    );
  }
  if (isLoggedIn === false) {
    return (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxStyled>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="primary"
                  onClick={handleBackController}
                  startIcon={<ArrowBackIos />}
                >
                  Atrás
                </Button>
              </Stack>
            </Box>
            <AddUserAddress
              isLoggedIn={isLoggedIn}
              onClose={handleClose}
              onSubmit={() => {
                setShowAddAddress(false);
                setShowEditAddress(false);
                setActiveStep(0);
              }}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBackController}
            />
          </BoxStyled>
        </Modal>
      </>
    );
  }
};
