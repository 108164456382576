import { NearShop } from 'api/chaletApi';
import dayjs from 'dayjs';

//is used when the data provide from NearShop
export const isStoreOpen = (shop: NearShop) => {
  const ActualTime = dayjs();

  const segundos =
    ActualTime.hour() * 3600 + ActualTime.minute() * 60 + ActualTime.second();

  return segundos >= shop.opensAt && segundos <= shop.closesAt;
};

export const getStoreStatusCode = (shop: NearShop) => {
  const ActualTime = dayjs();
  const segundos =
    ActualTime.hour() * 3600 + ActualTime.minute() * 60 + ActualTime.second();

  // Si la tienda aún no ha abierto
  if (segundos <= shop.opensAt) {
    return 0; // Código para "Tienda cerrada, aún no ha abierto"
  }

  // Si la tienda ya ha cerrado
  if (segundos >= shop.closesAt) {
    return 1; // Código para "Tienda cerrada, ya ha cerrado"
  }

  // La tienda está abierta
  return 2; // Código para "Tienda abierta"
};

//is used in ShopPage
export const isShopOpen = (schedule: any) => {
  const closes = schedule.closes;
  const opens = schedule.opens;
  const formattedCloses = dayjs(closes, 'h:mm A').format('HH:mm');
  const formattedOpens = dayjs(opens, 'h:mm A').format('HH:mm');

  if (schedule.closed) {
    return false;
  }

  const currentTime = dayjs();
  const openingTime = dayjs(formattedOpens, 'HH:mm'); // Formato de 24 horas
  const closingTime = dayjs(formattedCloses, 'HH:mm'); // Formato de 24 horas

  if (currentTime.isAfter(openingTime) && currentTime.isBefore(closingTime)) {
    return true; // La tienda está abierta
  } else {
    return false; // La tienda está cerrada
  }
};

export const getShopStatusCode = (schedule: any) => {
  const closes = schedule.closes;
  const opens = schedule.opens;
  const formattedCloses = dayjs(closes, 'h:mm A').format('HH:mm');
  const formattedOpens = dayjs(opens, 'h:mm A').format('HH:mm');

  const currentTime = dayjs();
  const openingTime = dayjs(formattedOpens, 'HH:mm'); // Formato de 24 horas
  const closingTime = dayjs(formattedCloses, 'HH:mm'); // Formato de 24 horas

  if (currentTime.isBefore(openingTime)) {
    return 0; // Código para "Tienda cerrada, aún no ha abierto"
  }

  if (currentTime.isAfter(closingTime)) {
    return 1; // Código para "Tienda cerrada, ya ha cerrado"
  }

  return 2; // Código para: "Tienda abierta"
};
