import React, { useState } from 'react';
import chaletApi from '../../../api/chaletApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import { ShopMenuTabPanel } from '../../components/partials/ShopMenuTabPanel';
import {
  DeliveryDining,
  Schedule,
  ShoppingCartOutlined,
} from '@mui/icons-material';
import { BackButton } from '../../components/common/BackButton/BackButton';
import { getCurrentDay } from 'utils/date';
import { Cart } from 'ui/components/partials/Cart';
import { ButtonCart, CustomAvatar, imgStyle } from './style';
import { getShopStatusCode, isShopOpen } from 'utils/store';
import { formatTime } from 'utils/formatter';

export const Shop = () => {
  const { shopId } = useParams();

  const id = parseInt(shopId || '0', 10);
  const { data, isLoading, isError } = chaletApi.useGetShopByIdQuery(id);
  //const [openSchedule, setOpenSchedule] = React.useState(false);
  const [openCart, setOpenCart] = React.useState(false);

  React.useEffect(() => {
    setOpenCart(false);
  }, [id]);

  if (shopId === undefined) {
    return null;
  }

  //const handleClose = () => setOpenSchedule(false);

  const handleStateViewDrawer = () => {
    setOpenCart((state) => !state);
  };

  let shop = null;
  if (data) {
    shop = data;
  }
  if (isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }
  if (isError) {
    toast('Ha ocurrido un error al obtener la tienda');
  }

  if (shop) {
    const schedule = shop.schedule.map(({ day, start, end, closed }: any) => {
      const startFormat = formatTime(start);
      const endFormat = formatTime(end);

      return {
        day,
        opens: startFormat,
        closes: endFormat,
        closed: closed,
      };
    });
    const currentDay = getCurrentDay();

    const currentSchedule = schedule.find((x: any) => x.day === currentDay);

    return (
      <>
        <BackButton
          toggleOpenCart={handleStateViewDrawer}
          schedule={currentSchedule}
        />
        <Container maxWidth="md">
          <Paper>
            <Box>
              <Box display="flex" justifyContent="center">
                <img style={imgStyle} src={shop.coverImage} alt="Cover" />
              </Box>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={8} sm={8} md={8}>
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {shop.name}
                    </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                      <Schedule sx={{ marginRight: '0.5rem' }} />
                      {currentSchedule?.closed ? (
                        <Typography>CERRADO</Typography>
                      ) : (
                        <>
                          {getShopStatusCode(currentSchedule) === 1 ? (
                            // Caso cuando la tienda está cerrada
                            <Typography>CERRADO</Typography>
                          ) : getShopStatusCode(currentSchedule) === 0 ? (
                            // Caso cuando la tienda aún no ha abierto

                            <Typography variant="body2">
                              Abre a las {currentSchedule?.opens}
                            </Typography>
                          ) : (
                            // Caso cuando la tienda está abierta
                            <Typography variant="body2">
                              {currentSchedule?.opens} -{' '}
                              {currentSchedule?.closes}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>

                    <Box display={'flex'} alignItems={'center'}>
                      <DeliveryDining sx={{ marginRight: '0.5rem' }} />
                      <Typography variant="body2">
                        {shop.estimatedOrderTime} -{' '}
                        {shop.estimatedOrderTime + 15}
                        min
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} alignItems={'center'}>
                  <Box display={'flex'} justifyContent={'center'}>
                    <CustomAvatar src={shop.profileImage} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ pr: 2, pl: 2 }}>
                    <Typography style={{ fontSize: 16 }} maxWidth={'450px'}>
                      {shop.description}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <ShopMenuTabPanel menu={shop.menus} />

            <ButtonCart
              sx={{ display: { xs: 'flex', sm: 'none' } }}
              variant="contained"
              onClick={() => handleStateViewDrawer()}
              startIcon={<ShoppingCartOutlined />}
              disabled={!isShopOpen(currentSchedule)}
            >
              Ver carrito
            </ButtonCart>
          </Paper>
          <Cart
            open={openCart}
            handleStateViewDrawer={handleStateViewDrawer}
            shopId={id}
          />
        </Container>
      </>
    );
  }
};
