import { Avatar, Box, Card, Grid, Typography, styled } from '@mui/material';

export const CustomGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'center',
  paddingRight: 20,
  paddingLeft: 20,
  paddingBottom: 20,
}));

export const CustomBox = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  justifyItems: 'center',
  padding: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: 0,
  },
}));

export const CustomImage = styled(Avatar)(({ theme }) => ({
  borderRadius: '5px',
  width: 400,
  height: 400,
  aspectRatio: '1/1',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    width: 350,
    height: 350,
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: '0px',
    width: '100%',
    height: '100%',
  },
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    justifyContent: 'left',
  },
}));

export const CustomCard = styled(Card)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    zIndex: 99,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.2)',
  },
}));
