import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import chaletApi from '../../../api/chaletApi';
import { toCurrencyFormat } from '../../../utils/formatter';
import { ProductVariation } from '../../components/partials/ProductVariation';
import { toast } from 'react-hot-toast';
import { addToCart } from 'redux/slices/cartSlice';
import { BackButton } from '../../components/common/BackButton/BackButton';
import {
  CustomBox,
  CustomCard,
  CustomGrid,
  CustomImage,
  CustomTypography,
} from './style';
import { useAppDispatch } from 'redux/hook';
import { customAlphabet, nanoid } from 'nanoid';
import routesConstants from 'utils/routesConstants';

interface VariationBodyState {
  id: number;
  addedValue: number;
  value: string;
  variationId: number;
}

export const Product = () => {
  const { shopId, productId } = useParams<{
    shopId: string;
    productId: string;
  }>();
  const id = parseInt(productId || '0', 10);
  const { data, isLoading, isError } = chaletApi.useGetProductByIdQuery(id);
  const [VariationBody, setVariationBody] = React.useState<
    Record<string, VariationBodyState>
  >({});
  const [quantity, setQuantity] = useState<number>(1);
  const [notes, setNotes] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let product: any = null;

  if (isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }
  if (isError) {
    toast.error('Ha ocurrido un error al obtener la tienda');
  }

  if (data) {
    product = data;

    const handleInitial = (
      initialRadioState: Record<string, VariationBodyState>,
    ) => {
      setVariationBody(initialRadioState);
    };

    const HandleVariationsBodyChange = (data: {
      variationId: number;
      variationOptionId: number;
      value: string;
      addedValue: number;
      type: string;
    }) => {
      const { variationId, variationOptionId: id, type } = data;

      setVariationBody((prevState) => {
        const updatedVariationBody = { ...prevState };

        if (type === 'radio') {
          // Si es una opción de radio, eliminamos las opciones anteriores del mismo variationId
          for (const existingKey in updatedVariationBody) {
            if (updatedVariationBody[existingKey].variationId === variationId) {
              delete updatedVariationBody[existingKey];
            }
          }

          updatedVariationBody[id] = {
            variationId,
            id,
            value: data.value,
            addedValue: data.addedValue,
          };
        } else {
          // Si es una opción de checkbox, alternamos su estado
          if (updatedVariationBody[id]) {
            delete updatedVariationBody[id];
          } else {
            updatedVariationBody[id] = {
              variationId,
              id,
              value: data.value,
              addedValue: data.addedValue,
            };
          }
        }

        return updatedVariationBody;
      });
    };

    const increaseQuantity = () => {
      setQuantity(quantity + 1);
    };

    const decreaseQuantity = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    };

    const handleChangeNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotes(event.target.value);
    };

    const ProductWithAddedValue = () => {
      let totalAddedValue: number = 0;

      Object.values(VariationBody).forEach((variation) => {
        totalAddedValue += variation.addedValue;
      });

      const ProductWithAddedValue = product.price + totalAddedValue;
      //const totalPrice = quantity * ProductWithAddedValue;

      return ProductWithAddedValue;
    };

    const uniqueId = customAlphabet('1234567890', 10);
    const handleAddToCart = async () => {
      const productData = {
        shopId: product.shopId,
        shopName: product.shop.name,
        shopImage: product.shop.profileImage,
        shopLocation: product.shop.location,
        shopDeliveryTime: product.shop.estimatedOrderTime,
        products: [
          {
            uniqueProductId: Number(uniqueId()),
            productId: product.id,
            name: product.name,
            quantity: quantity,
            image: product.image,
            unitPrice: product.price,
            price: ProductWithAddedValue(),
            total: ProductWithAddedValue() * quantity,
            notes: notes,
            variations: product.variations,
            options: VariationBody,
          },
        ],
      };

      await dispatch(addToCart(productData));
      toast.success('producto agregado');
      navigate(routesConstants.shop(shopId));
    };

    return (
      <>
        <BackButton shopId={shopId} />
        <Container maxWidth="md">
          <Paper elevation={1}>
            <CustomBox display="flex" flexDirection={'row'}>
              {product.image && (
                <Box display={'flex'}>
                  <CustomImage src={product.image} />
                </Box>
              )}

              <Box
                display={'flex'}
                flexDirection={'column'}
                flex={1}
                sx={{ marginLeft: 2, marginTop: 2 }}
              >
                <Box justifyContent={'start'}>
                  <CustomTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {product.name}
                  </CustomTypography>

                  <CustomTypography variant="subtitle2" maxWidth={'450px'}>
                    {product.description}
                  </CustomTypography>
                </Box>
              </Box>
            </CustomBox>
            <CustomGrid container justifyContent="center" alignItems="center">
              <Grid item md={12} sm={12} xs={12}>
                <ProductVariation
                  variations={product.variations}
                  onChange={HandleVariationsBodyChange}
                  initial={handleInitial}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box sx={{ marginBottom: { xs: '100px', md: '0px' } }}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Cambios adicionales"
                    name="notes"
                    multiline
                    rows={3}
                    helperText="Por ejemplo: Sin lechuga"
                    onChange={handleChangeNotes}
                  />
                </Box>
              </Grid>
              <Grid item md={12} xs={12} mt={2}>
                <CustomCard>
                  <Box flex={1} mt={1} mb={1}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Typography ml={2} mr={2} fontWeight={'bold'}>
                        Cantidad
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={decreaseQuantity}
                      >
                        <Remove />
                      </Button>
                      <Typography ml={2} mr={2}>
                        {quantity}
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={increaseQuantity}
                      >
                        <Add />
                      </Button>
                    </Box>

                    <Box display={'flex'} alignItems={'center'} sx={{ p: 1 }}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={handleAddToCart}
                      >
                        Agregar a mi orden
                      </Button>
                      <Typography ml={1} variant="h5">
                        {toCurrencyFormat(ProductWithAddedValue() * quantity)}
                      </Typography>
                    </Box>
                  </Box>
                </CustomCard>
              </Grid>
            </CustomGrid>
          </Paper>
        </Container>
      </>
    );
  }
};
