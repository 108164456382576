import { Button, Container, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import routesConstants from 'utils/routesConstants';

export default function DeleteAccount() {
  const subject = "Borrar mi cuenta";
  const body = "Deseo eliminar mi cuenta.";
  const navigate = useNavigate()
  const mailtoHref = `mailto:elchaletsv@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  const location = useLocation()
  const onClick = () => {
    if (location.key === 'default') {
      navigate(routesConstants.initial);
    } else {
      navigate(-1)
    }
  }

  return (
    <Container>
      <Button onClick={onClick} aria-label="back" sx={{ mt: 2, ml: 1, mr: 1, gap: 1 }}>
        <ArrowBackIcon fontSize="medium" />
        <Typography fontWeight={600}>
          Regresar
        </Typography>
      </Button>
      <Stack gap={2} m={2} mt={1} minHeight={700}>
        <Typography variant="h4">
          Muchas gracias por probar El Chalet, esperamos que vuelvas pronto.
        </Typography>
        <Typography variant="h5">
          Para eliminar tu cuenta favor envíanos un correo a <a style={{ color: '#ef3d32' }} href={mailtoHref}>elchaletsv@gmail.com</a>
        </Typography>
      </Stack>
    </Container>
  )
}
