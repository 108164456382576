import { MonetizationOn } from '@mui/icons-material';
import { Box, Typography, Grid, Checkbox, Stack } from '@mui/material';
import React from 'react';
import { CustomGrid, CustomTypography } from './style';
import { ShopCartItem } from 'types/ShopCart';
import { TicketCard } from './TicketCard';
import { DeliveryData } from 'types/DeliveryData';
import { RouteOrder } from './RouteOrder';
import { UserInfo } from './UserOrderInfo';
import { RouteMap } from '../../RouteMap';
import { useAppSelector } from 'redux/hook';

type ConfirmOrderProps = {
  currentShop: ShopCartItem;
  total: number;
  setDeliveryData: (data: DeliveryData) => void;
};

export const ConfirmOrder: React.FC<ConfirmOrderProps> = ({
  currentShop,
  total,
  setDeliveryData,
}) => {
  const [deliveryPrice, setDeliveryPrice] = React.useState<number>(0);

  const location = useAppSelector((state) => state.locationSlice);
  const [route, setRoute] = React.useState<any | null>(null);

  const handleDeliveryPrice = (deliveryPrice: number) => {
    setDeliveryPrice(deliveryPrice);
  };

  const handleRoute = (route: any) => {
    setRoute(route);
  };

  return (
    <CustomGrid container justifyContent="center" alignItems="center">
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="h6" fontWeight={800} color="black">
          Detalles de entrega
        </Typography>
        <RouteMap
          origin={currentShop.shopLocation}
          destination={location.location}
          handleRoute={handleRoute}
        />
      </Grid>

      {route && (
        <RouteOrder
          currentShop={currentShop}
          setDeliveryPrice={handleDeliveryPrice}
          setDeliveryData={setDeliveryData}
          route={route}
        />
      )}

      <Grid item md={12} sm={12} xs={12} mt={1}>
        <CustomTypography variant="body1" sx={{ fontWeight: 'bold' }}>
          Detalle de orden
        </CustomTypography>

        <TicketCard
          currentShop={currentShop}
          deliveryPrice={deliveryPrice}
          total={total}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} mt={1}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Metodo de pago
        </Typography>
        <Stack direction="row" spacing={1}>
          <MonetizationOn />
          <Typography>Efectivo</Typography>
        </Stack>
      </Grid>
    </CustomGrid>
  );
};
