import {
  Avatar,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hook';
import { getCurrentDay } from 'utils/date';
import { NearShopsCard } from '../NearShopsCard';
import routesConstants from 'utils/routesConstants';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

type ShopProps = {
  type: string;
  toggleSelectedCategory: (selectedCategory: string) => void;
  selectedCategory: string;
};

export const NearShopsList: React.FC<ShopProps> = ({
  type,
  toggleSelectedCategory,
  selectedCategory,
}) => {
  const navigate = useNavigate();
  const location = useAppSelector((state) => state.locationSlice);
  const [getNearShops, result] = chaletApi.useGetNearShopsMutation();
  const currentDay = getCurrentDay();

  const [uniqueCategories, setUniqueCategories] = useState<
    { name: string; icon: string }[]
  >([]);

  useEffect(() => {
    const checkLocationAndFetchShops = async () => {
      if (
        location &&
        Object.values(location).every((value) => value !== null)
      ) {
        await getNearShops({
          lat: location.location?.coordinates[1],
          lng: location.location?.coordinates[0],
          distance: 8000,
          day: currentDay,
          type: type,
        });
      } else {
        navigate(routesConstants.initial);
      }
    };

    checkLocationAndFetchShops();
  }, [location, type]);

  useEffect(() => {
    if (result.data) {
      const categories = result.data.reduce(
        (acc, shop) => {
          shop.categories.forEach((category) => {
            const existingCategory = acc.find((c) => c.name === category.name);

            if (!existingCategory) {
              acc.push({
                name: category.name,
                icon: category.image,
              });
            }
          });
          return acc;
        },
        [] as { name: string; icon: string }[],
      );

      categories.unshift({
        name: 'Todos',
        icon: 'https://cdn-icons-png.flaticon.com/512/3297/3297607.png',
      });

      setUniqueCategories(categories);
    }
  }, [result.data]);

  // Manejar el cambio de categoría seleccionada
  const handleCategoryChange = (
    event: React.SyntheticEvent,
    newCategory: string,
  ) => {
    toggleSelectedCategory(newCategory);
  };

  const OpenShop = (shopId: number) => {
    navigate(routesConstants.shop(shopId.toString()));
  };

  if (result.isError) {
    toast.error('Error obteniendo las tiendas');
  }

  if (result.isLoading) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  if (result.data) {
    const data = result.data;

    return (
      <>
        {result.data && result.data.length > 0 ? (
          <>
            <Box
              display={'flex'}
              justifyContent={'center'}
              sx={{
                maxWidth: { xs: '100vw', sm: '100%' },
                bgcolor: 'background.paper',
              }}
            >
              <Tabs
                value={selectedCategory || 'Todos'}
                onChange={handleCategoryChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {uniqueCategories.map((category) => (
                  <Tab
                    key={category.name}
                    label={category.name}
                    value={category.name}
                    icon={
                      <Avatar
                        sx={{ width: 30, height: 30 }}
                        variant="square"
                        src={category.icon}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>

            {uniqueCategories.map((category) => (
              <TabPanel
                key={category.name}
                value={selectedCategory || 'Todos'}
                index={category.name}
              >
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'flex' },
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {selectedCategory === 'Todos'
                    ? data.map((shop) => (
                      <NearShopsCard
                        key={shop.id}
                        shop={shop}
                        OpenShop={OpenShop}
                      />
                    ))
                    : // Mostrar tiendas de la categoría seleccionada
                    data.map(
                      (shop) =>
                        shop.categories.some(
                          (c) => c.name === category.name,
                        ) && (
                          <NearShopsCard
                            key={shop.id}
                            shop={shop}
                            OpenShop={OpenShop}
                          />
                        ),
                    )}
                </Box>
              </TabPanel>
            ))}
          </>
        ) : (
          <>
            <Typography variant="subtitle1" align="center" mt={4}>
              No se encontraron tiendas cercanas
            </Typography>
            <Typography
              variant="body2"
              color={'textSecondary'}
              align="center"
              mt={1}
            >
              Pronto ampliaremos nuestra cobertura, puedes intentarlo más tarde.
            </Typography>
          </>
        )}
      </>
    );
  }
};
