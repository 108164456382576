import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { toCurrencyFormat } from 'utils/formatter';
import { ShopCartItem } from 'types/ShopCart';

type TicketCardProps = {
  currentShop: ShopCartItem;
  deliveryPrice: number;
  total: number;
};

export const TicketCard: React.FC<TicketCardProps> = ({
  currentShop,
  deliveryPrice,
  total,
}) => {
  return (
    <Card>
      <CardContent>
        <Box display={'flex'}>
          <Typography
            flex={1}
            sx={{ fontSize: 14, fontWeight: 'bold' }}
            gutterBottom
          >
            Productos
          </Typography>
          <Typography flex={0} sx={{ fontSize: 14 }} gutterBottom>
            {toCurrencyFormat(total)}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography
            flex={1}
            sx={{ fontSize: 14, fontWeight: 'bold' }}
            gutterBottom
          >
            Delivery
          </Typography>
          <Typography flex={0} sx={{ fontSize: 14 }} gutterBottom>
            {toCurrencyFormat(deliveryPrice)}
          </Typography>
        </Box>
        <Box display={'flex'}>
          <Typography flex={1} sx={{ fontSize: 14, fontWeight: 'bold' }}>
            Total
          </Typography>
          <Typography flex={0} sx={{ fontSize: 14, fontWeight: 'bold' }}>
            {toCurrencyFormat(total + deliveryPrice)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
