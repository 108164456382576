import React from 'react';
import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import { Order } from 'types/Order';
import { OrderModal } from 'ui/components/partials/Order/OrderModal';
import { OrderCard } from 'ui/components/partials/Order/OrderCard';
import { useAppSelector } from 'redux/hook';
import { SingIn } from 'ui/components/common/SingIn';

export const Orders: React.FC = () => {
  const { isLoading, isError, data } = chaletApi.useGetOrdersQuery();
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const [page, setPage] = React.useState<number>(1);
  const [filter, setFilter] = React.useState<string>('todas'); // 'all', 'active', 'completed', 'canceled'
  const ordersPerPage = 8;

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    setPage(1); // Reset page to 1 when changing filters
  };

  const filterOrders = (order: Order) => {
    if (filter === 'todas') {
      return true;
    } else if (filter === 'activas') {
      return order.isActive;
    } else if (filter === 'completadas') {
      return order.isCompleted;
    } else if (filter === 'canceladas') {
      return order.isCanceled;
    }
    return true;
  };

  const renderOrders = () => {
    if (!isLoggedIn) {
      return <SingIn />;
    }

    if (isLoading) {
      return (
        <Box
          sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
          padding={4}
        >
          <Box>
            <CircularProgress size={100} color="primary" />
          </Box>
        </Box>
      );
    }

    if (isError) {
      return (
        <p>
          Error: No se pudo cargar los datos. Por favor, inténtalo de nuevo más
          tarde.
        </p>
      );
    }

    if (data) {
      const filteredOrders = data.filter(filterOrders);
      const startIndex = (page - 1) * ordersPerPage;
      const endIndex = startIndex + ordersPerPage;

      return (
        <Container maxWidth="md">
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={1}
          >
            <Typography>Ordenar por:</Typography>
            <Select
              variant="standard"
              value={filter}
              onChange={(event) => handleFilterChange(event.target.value)}
              sx={{ m: 0.5 }}
            >
              {['todas', 'activas', 'completadas', 'canceladas'].map(
                (status) => (
                  <MenuItem key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </MenuItem>
                ),
              )}
            </Select>
          </Stack>

          {filteredOrders.slice(startIndex, endIndex).map((order: any) => (
            <OrderInfo key={order.id} order={order} />
          ))}
          <Pagination
            count={Math.ceil(filteredOrders.length / ordersPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Container>
      );
    }
  };

  return <>{renderOrders()}</>;
};

type OrderInfoProps = {
  order: Order;
};

const OrderInfo: React.FC<OrderInfoProps> = ({ order }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <OrderCard order={order} handleOpen={handleOpen} />

      <OrderModal handleClose={handleClose} open={open} orderId={order.id} />
    </>
  );
};
