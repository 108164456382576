import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { ReactComponent as ElchaletLogo } from '../../../../assets/ic_logo_no_title.svg';
import { IconButton } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useState } from 'react';
import { LocationsModal } from '../../partials/Locations/LocationsModal';
import { useAppSelector } from 'redux/hook';
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';

const StyledAppbar = styled(AppBar)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
  },
}));

type Page = {
  name: string;
  route: string;
};

type TopBarProps = {
  pages: Page[];
};

export const TopBar: React.FC<TopBarProps> = ({ pages }) => {
  const location = useAppSelector((state) => state.locationSlice);
  const [locationModal, setLocationModal] = useState(false);

  const handleLocationModal = () => setLocationModal(false);

  const navigate = useNavigate();

  if (!pages) {
    return null;
  }

  const goTo = (route: string) => () => {
    navigate(route, { replace: false });
  };

  return (
    <StyledAppbar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ margin: { xs: 0, md: 1 } }}>
            <IconButton onClick={goTo('/')}>
              <Box
                style={{ width: '44px', height: '44px' }}
                display="flex"
                alignItems="center"
              >
                <ElchaletLogo />
              </Box>
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontWeight: 500,
              color: 'white.main',
              textDecoration: 'none',
            }}
          >
            El Chalet
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map(({ name, route }) => (
              <Button
                key={route}
                variant="text"
                onClick={goTo(route)}
                style={{ color: 'white', fontSize: 18, textTransform: 'none' }}
              >
                {name}
              </Button>
            ))}
          </Box>
          <Box flex={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              disableElevation
              startIcon={<LocationOn />}
              onClick={() => setLocationModal(true)}
            >
              {location.name
                ? location.name.length > 12
                  ? location.name.substring(0, 12) + '...'
                  : location.name
                : 'Ingresa una ubicación'}
            </Button>
          </Box>
        </Toolbar>
      </Container>

      <LocationsModal open={locationModal} handleClose={handleLocationModal} />
    </StyledAppbar>
  );
};
