import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  LinearProgress,
  Stack,
  Typography,
  makeStyles,
  useTheme,
} from '@mui/material';
import { formatDate, formatTimeString } from 'utils/formatter';

type Props = {
  steps: number;
  currentStep: number;
  lastUpdatedTime?: string;
  stepName?: string;
  icon?: string;
};

export const OrderProcessStepper = ({
  steps,
  currentStep,
  lastUpdatedTime,
  stepName,
  icon,
}: Props) => {
  const [progress, setProgress] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    setProgress((currentStep - 1) / (steps - 1));
  }, [currentStep, steps]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flex: 1,
      }}
    >
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent="space-between"
        width="100%"
      >
        {Array.from({ length: steps }).map((_, index) => {
          const stepNumber = index + 1;
          const isLastStep = stepNumber === currentStep;
          return (
            <Box
              display={'flex'}
              sx={{
                zIndex: 2,
                width: { xs: 36, md: 50 },
                height: { xs: 36, md: 50 },
                borderRadius: '50%',
                border: '2px solid',
                borderColor:
                  stepNumber <= currentStep
                    ? theme.palette.primary.main
                    : theme.palette.grey[400],
                backgroundColor:
                  stepNumber <= currentStep
                    ? theme.palette.primary.main
                    : theme.palette.white.main,

                alignItems: 'center',
                justifyContent: 'center',
              }}
              key={index}
            >
              {icon && isLastStep && (
                <Avatar
                  src={icon}
                  sx={{ width: { xs: 36, md: 50 }, height: { xs: 36, md: 50 } }}
                />
              )}
            </Box>
          );
        })}

        <LinearProgress
          sx={{
            position: 'absolute',
            zIndex: 1,
            width: '99%',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.grey[400],
          }}
          variant="determinate"
          value={progress * 100}
        />
      </Box>

      <Stack
        display={'flex'}
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="subtitle2" fontSize={13}>
          {stepName}
        </Typography>
        <Typography variant="subtitle2" fontSize={13} m={-0.5}>
          {lastUpdatedTime && formatTimeString(lastUpdatedTime)}
        </Typography>
      </Stack>
    </Box>
  );
};
