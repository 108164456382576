import { AccessTime, LocationOn } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import chaletApi from 'api/chaletApi';
import React, { useMemo } from 'react';
import { useAppSelector } from 'redux/hook';
import { DeliveryData } from 'types/DeliveryData';
import { ShopCartItem } from 'types/ShopCart';
import { addMinutesToTime, getCurrentTime } from 'utils/hours';
import { CustomTypography } from '../style';
import { RouteMap } from 'ui/components/partials/RouteMap';

// Función para calcular el precio de entrega
const calculateDeliveryPrice = (
  distanceValue: number,
  deliveryDistanceFactor: number,
  orderProcessPrice: number,
) => {
  let deliveryPrice = orderProcessPrice;
  if (distanceValue > deliveryDistanceFactor) {
    const distanceFactor = distanceValue / deliveryDistanceFactor;
    deliveryPrice = parseFloat((orderProcessPrice * distanceFactor).toFixed(2));
  }

  return deliveryPrice;
};

// Función para calcular el tiempo estimado de entrega
const calculateEstimatedDeliveryTime = (
  route: any,
  currentShopDeliveryTime: number,
) => {
  const durationValue = route.routes[0].legs[0].duration.value;
  const RoundedDuration = Math.ceil(durationValue / 60);
  return RoundedDuration + currentShopDeliveryTime + 10;
};

const weightedAverage = (
  distancePrice: number,
  timePrice: number,
  weightDistance = 0.6,
  weightTime = 0.4,
) => {
  return (
    (distancePrice * weightDistance + timePrice * weightTime) /
    (weightDistance + weightTime)
  );
};

type RouteOrderProps = {
  currentShop: ShopCartItem;
  setDeliveryPrice: (deliveryPrice: number) => void;
  setDeliveryData: (data: DeliveryData) => void;
  route: any;
};

export const RouteOrder: React.FC<RouteOrderProps> = ({
  currentShop,
  setDeliveryPrice,
  setDeliveryData,
  route,
}) => {
  const { data: orderProcess, isFetching: orderIsFetching } =
    chaletApi.useGetOneOrderProcessQuery(1);

  const location = useAppSelector((state) => state.locationSlice);
  const [estimatedDeliveryTime, setEstimatedDeliveryTime] =
    React.useState<number>();
  const [currentTime, setCurrentTime] = React.useState<string>(
    getCurrentTime(),
  );

  const routeDeliveryTime = route.routes[0].legs[0].duration.value;
  const routeDistance = route.routes[0].legs[0].distance.value;

  const totalDeliveryPrice = useMemo(() => {
    if (!routeDistance || !orderProcess || !routeDeliveryTime) {
      return null;
    }
    const distanceFactor = 2200;
    const f = routeDistance / distanceFactor;
    const distanceDeliveryPrice = orderProcess.price * f;

    const timeFactor = 300;
    const f2 = routeDeliveryTime / timeFactor;
    const timeDeliveryPrice = orderProcess.price * f2;

    let deliveryPrice = weightedAverage(
      distanceDeliveryPrice,
      timeDeliveryPrice,
    );

    if (deliveryPrice < orderProcess.price) {
      deliveryPrice = orderProcess.price;
    }

    /*     console.log('------------------');
    console.log('Calculated delivery price');
    console.log(
      `timeDeliveryPrice: ${routeDeliveryTime / 60} Minutes`,
      timeDeliveryPrice,
    );
    console.log(
      `distanceDeliveryPrice: ${routeDistance} Mts`,
      distanceDeliveryPrice,
    );
    console.log('Total: $', deliveryPrice); */

    return deliveryPrice;
  }, [routeDistance, routeDeliveryTime, orderProcess]);

  const deliveryTime = useMemo(() => {
    const estimatedDeliveryTime = calculateEstimatedDeliveryTime(
      route,
      currentShop.shopDeliveryTime,
    );

    return estimatedDeliveryTime;
  }, [route, currentShop]);

  React.useEffect(() => {
    if (orderProcess && route && typeof totalDeliveryPrice === 'number') {
      const newDeliveryData = {
        orderProcessId: orderProcess.id,
        currentOrderProcessStepId: 1,
        deliveryPrice: totalDeliveryPrice,
        estimatedDeliveryTime: `${deliveryTime} minutes`,
        deliveryType: orderProcess.name,
      };
      setDeliveryData(newDeliveryData);
      setDeliveryPrice(totalDeliveryPrice);
    }

    setEstimatedDeliveryTime(deliveryTime);
  }, [route, orderProcess, totalDeliveryPrice, deliveryTime]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 60000); // 60000 milisegundos = 1 minuto

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []);

  if (orderIsFetching) {
    return (
      <Box
        sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
        padding={4}
      >
        <Box>
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Grid item md={12} sm={12} xs={12} mt={1}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box flex={0} mr={1}>
            <LocationOn />
          </Box>
          <Box flex={1}>
            <CustomTypography variant="body1" sx={{ fontWeight: 'bold' }}>
              {location.address}
            </CustomTypography>
          </Box>
        </Box>
        <Divider variant="middle"></Divider>
      </Grid>
      <Grid item md={12} sm={12} xs={12} mt={1}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box flex={0} mr={1}>
            <AccessTime />
          </Box>
          <Box flex={1}>
            <CustomTypography variant="body1" sx={{ fontWeight: 'bold' }}>
              Hora estimada de entrega:{' '}
            </CustomTypography>
          </Box>
          <Box flex={0}>
            <Typography variant="body2" noWrap>
              {estimatedDeliveryTime
                ? ` ${addMinutesToTime(currentTime, estimatedDeliveryTime)}`
                : ''}
            </Typography>
          </Box>
        </Box>
        <Divider variant="middle"></Divider>
      </Grid>
    </>
  );
};
