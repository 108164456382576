import { Avatar, Box, Grid, Typography, styled } from '@mui/material';

export const CustomGrid = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  justifyItems: 'center',
}));

export const CustomImage = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  maxHeight: '500px',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    width: '90%',
    height: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '100%',
  },
}));

export const CustomTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {},
}));
