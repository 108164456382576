import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LocationsModal } from '../../components/partials/Locations/LocationsModal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hook';
import foodLeft from '../../../assets/food-left.webp';
import foodRight from '../../../assets/food-right.webp';
import { ReactComponent as ElchaletLogo } from '../../../assets/ic_logo_no_title.svg';
import SecondaryOptions from './components/SecondaryOptions';
import routesConstants from 'utils/routesConstants';

export const Home = () => {
  const location = useAppSelector((state) => state.locationSlice);
  const [locationModal, setLocationModal] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (location && Object.values(location).every((value) => value !== null)) {
      navigate(routesConstants.home);
    }
  }, [location, navigate]);

  const handleLocationModal = () => setLocationModal(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onLogin = () => {
    navigate(routesConstants.login);
  }
  const onSignUp = () => {
    navigate(routesConstants.signUp);
  }
  return (
    <Box sx={{
      backgroundColor: '#ef3d32',
      zIndex: 1
    }} >
      <Box sx={{ overflow: 'hidden', position: 'absolute', display: 'flex', left: 0, right: 0, top: 0, flexDirection: 'row', justifyContent: 'space-between', zIndex: -1 }}>
        <Box >
          <img src={foodLeft} height={"auto"} style={{ width: isMedium ? 200 : 400, transform: isMedium ? 'translateX(-90px)' : 'translateX(-50px)' }} />
        </Box>
        <Box flex={1} />
        <Box >
          <img src={foodRight} height={"auto"} style={{ width: isMedium ? 200 : 400, transform: isMedium ? 'translateX(60px)' : 'translateX(50px)' }} />
        </Box>
      </Box >
      <Container>
        <Stack direction="column" alignItems="center" mt={10} mb={10} gap={6} >
          <Box>
            <ElchaletLogo width={200} height={100} />
            <Typography
              variant="h3"
              color="background.paper"
              sx={{ fontWeight: 'bold', mb: 2, p: 1 }}
              textAlign={'center'}
            >
              El Chalet
            </Typography>
          </Box>
          {
            isLoggedIn && <Typography color="white.main" fontWeight={700} variant="h4" ml={8} mr={8} gutterBottom>
              Bienvenido/a, {user?.name}
            </Typography>
          }
          <Typography color="white.main" fontWeight={700} variant="h4" ml={8} mr={8} gutterBottom>
            Compra en comercios locales <br /> con un click
          </Typography>
          <Button size="large" sx={{ width: isMobile ? '80%' : 300 }} variant="contained" color='secondary' onClick={() => setLocationModal(true)}>
            Buscar comercios cercanos
          </Button>
          {
            !isLoggedIn && (
              <Grid item container maxWidth="sm" sx={{ backgroundColor: 'white.main' }} spacing={2} pt={2} pb={4}>
                <Grid container item xs={12} justifyContent="center">
                  <Typography color="primary.main" fontWeight={500} variant="h6" ml={8} mr={8} gutterBottom>
                    ¿Ya tienes una cuenta?
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <Button onClick={onLogin} size="large" sx={{ width: isMobile ? '80%' : 300 }} variant="contained" >
                    Iniciar sesión
                  </Button>
                </Grid>
                <Grid item container justifyContent="center">
                  <Button onClick={onSignUp} size="large" sx={{ width: isMobile ? '80%' : 300, }} variant="text" >
                    Registrarme
                  </Button>
                </Grid>
              </Grid>
            )
          }
          <SecondaryOptions />
        </Stack>
      </Container>
      <LocationsModal
        open={locationModal}
        handleClose={handleLocationModal}
      />
    </Box >
  );
};