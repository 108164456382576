import { Button, Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import orderImg from '../../../../assets/img-store.webp'
import { useNavigate } from "react-router-dom";
import routesConstants from 'utils/routesConstants';

export default function SecondaryOptions() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigateToShopRegistration = () => {
    navigate(routesConstants.shopSignUp);
  }

  return (
    <Card sx={{ alignItems: 'center', width: isMobile ? '80%' : 300, margin: '0 auto' }}> {/* Responsive width */}
      <Stack alignItems="stretch" gap={1} pb={2}>
        <img src={orderImg} style={{ objectFit: 'cover', width: '100%', height: 250 }} />
        <Typography variant="h5" fontWeight={500} color="primary.main" sx={{ pl: 2, pr: 2, pt: 2, textAlign: 'left' }}>
          Registrar mi negocio
        </Typography>
        <Typography variant="body1" sx={{ pr: 2, pl: 2 }}>
          Recibe pedidos, aumenta tus ventas y llega a más clientes.
        </Typography>
        <Button size="large" variant="outlined" onClick={navigateToShopRegistration} sx={{ marginLeft: 2, marginRight: 2, flex: 1 }}>
          Registrar
        </Button>
      </Stack>
    </Card>
  )
}
