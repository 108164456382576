import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import chaletApi from '../../../../api/chaletApi';
import { Card, Divider, Grid, Paper, styled } from '@mui/material';
import { toCurrencyFormat } from '../../../../utils/formatter';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuCategory } from 'types/Shop';
import { Product } from 'types/Product';
import routesConstants from 'utils/routesConstants';

type ShopMenuTabPanelProps = {
  menu: Menu[];
};
export const ShopMenuTabPanel: React.FC<ShopMenuTabPanelProps> = ({ menu }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (menu) {
    return (
      <>
        {menu.map((menu, i) => {
          return <MenuPanel key={i} MenuCategories={menu.menuCategories} />;
        })}
      </>
    );
  }
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

type MenuPanelProps = {
  MenuCategories: MenuCategory[];
};
const MenuPanel: React.FC<MenuPanelProps> = ({ MenuCategories }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (MenuCategories.length > 0) {
      setValue(0);
    }
  }, [MenuCategories]);

  return (
    <>
      <Box
        display={'flex'}
        sx={{
          maxWidth: { xs: '100vw', sm: '100%' },
        }}
      >
        <Tabs
          style={{ maxWidth: '1000px', marginLeft: '10px' }}
          variant="scrollable"
          value={value}
          onChange={handleChange}
        >
          {MenuCategories.map((cat, i) => (
            <Tab key={i} label={cat.name} />
          ))}
        </Tabs>
      </Box>
      {MenuCategories.map((cat, i) => (
        <TabPanel key={i} value={value} index={i}>
          <CategoryProductList products={cat.products} />
        </TabPanel>
      ))}
    </>
  );
};

const CustomBox = styled(Box)(({ theme }) => ({
  marginTop: 8,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    '& img': {
      width: 125,
      height: 125,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: 80,
      height: 80,
    },
    '& .MuiTypography-root': {
      fontSize: '1rem',
    },
    '& .MuiBox-root': {
      margin: 3,
    },
  },
}));

type CategoryProductListProps = {
  products: Product[];
};

const CategoryProductList: React.FC<CategoryProductListProps> = ({
  products,
}) => {
  const navigate = useNavigate();

  const OpenProduct = (shopId: number, productId: number) => {
    navigate(routesConstants.shopProduct(shopId, productId));
  };

  if (products) {
    return (
      <>
        {products.map((product) => (
          <Box
            key={product.id}
            onClick={() => OpenProduct(product.shopId, product.id)}
          >
            <CustomBox>
              <Box display={'flex'}>
                {product.image && (
                  <Box mr={1}>
                    <img
                      width={150}
                      height={150}
                      src={product.image}
                      style={{
                        borderRadius: '5px',
                        aspectRatio: '1/1',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                )}
                <Box display={'flex'} flexDirection={'column'} flex={1}>
                  <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                    {product.name}
                  </Typography>
                  <Typography style={{ fontSize: 15 }}>
                    {product.description}
                  </Typography>
                </Box>

                <Box alignSelf={'self-end'}>
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'flex', justifyContent: 'end' }}
                  >
                    {toCurrencyFormat(product.price)}
                  </Typography>
                </Box>
              </Box>
            </CustomBox>
            <Divider sx={{ mb: 0.5 }}></Divider>
          </Box>
        ))}
      </>
    );
  }
};

/*   <CustomCard
              key={product.id}
              elevation={4}
              onClick={() => OpenProduct(product.shopId, product.id)}
            >
              <Box display="flex" margin={2} alignItems="center">
                <Box flex={0} width={100}>
                  <img
                    width={100}
                    height={100}
                    src={product.image}
                    style={{ objectFit: 'contain' }}
                  />
                </Box>
                <Box flex={1} ml={2} mr={2} >
                  <Typography
                    justifyContent={'center'}
                    alignItems={'center'}
                    variant="h6"
                  >
                    {product.name}
                  </Typography>
                 
                </Box>
                <Box flex={0}>
                  <Typography variant="h5">
                    {toCurrencyFormat(product.price)}
                  </Typography>
                </Box>
              </Box>
            </CustomCard> */
