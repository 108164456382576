import React from 'react';
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { ReactComponent as AppstoreButton } from '../../../../assets/ic_download_appstore.svg';
import googlePlayImage from '../../../../assets/get_on_google_play.png';

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const openPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.chalatech.delivery_app_android',
      '_blank',
    );
  };
  const openAppStore = () => {
    window.open(
      'https://apps.apple.com/us/app/el-chalet/id6448647632',
      '_blank',
    );
  };

  const goTo = (route: string) => () => {
    window.open(route, '_blank');
  };

  return (
    <footer style={{ justifySelf: 'flex-end' }}>
      <Paper >
        <Grid container bgcolor="secondary.main">
          <Grid item container justifyContent="center" sx={{ pt: 4, pb: 4 }}>
            <Grid item container justifyContent="flex-end">
              <Grid xs={12} md={6} item container justifyContent={isMobile ? 'center' : 'end'}>
                <ButtonBase onClick={openPlayStore} >
                  <img src={googlePlayImage} alt="Get it on Google Play" style={{ height: 95, }} />
                </ButtonBase>
              </Grid>
              <Grid xs={12} md={6} item container justifyContent={isMobile ? 'center' : 'start'}>
                <ButtonBase onClick={openAppStore} >
                  <AppstoreButton style={{ height: 100, width: 200 }} />
                </ButtonBase>
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
              <Grid item container justifyContent="center">
                <Typography color="white.main" textAlign="center" fontWeight={600}>
                  Social
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={goTo(
                    'https://www.facebook.com/El-Chalet-Sv-111158361627905',
                  )}
                >
                  <Box padding={0.5} color="grey.800">
                    <FacebookIcon style={{ color: theme.palette.white.main }} />
                  </Box>
                </IconButton>
                <IconButton
                  onClick={goTo('https://www.instagram.com/el_chaletsv')}
                >
                  <Box padding={0.5} color="grey.800">
                    <InstagramIcon style={{ color: theme.palette.white.main }} />
                  </Box>
                </IconButton>
              </Grid>
            </Grid>

            <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
              <Grid item container justifyContent="center">
                <Typography color="white.main" textAlign="center" fontWeight={600}>
                  Legal
                </Typography>
              </Grid>
              <Box justifyContent="center" display="flex" margin={2}>
                <Link to="/terms-and-conditions" style={{ color: theme.palette.white.main }}>
                  <Typography>Términos y condiciones</Typography>
                </Link>
              </Box>
            </Grid>

            <Stack flex={1} gap={2}>
              <Typography color="white.main" textAlign="center" fontWeight={600}>
                Contacto
              </Typography>
              <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
                <EmailIcon style={{ color: theme.palette.white.main }} />
                <Typography
                  style={{ color: theme.palette.white.main }}
                  component="a"
                  textAlign="center"
                  href="mailto:name@elchaletsv@gmail.com"
                >
                  elchaletsv@gmail.com
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
                <PhoneIcon style={{ color: theme.palette.white.main }} />
                <Typography
                  style={{ color: theme.palette.white.main }}
                  component="a"
                  textAlign="center"
                  href="tel:7237-5695"
                >
                  +503 7237-5695
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </footer>
  );
};
