import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';
import { Order } from 'types/Order';
import {
  formatDateWithTime,
  formatTimeString,
  toCurrencyFormat,
} from 'utils/formatter';
import { OrderProcessStepper } from '../OrderProcessStepper';
import chaletApi from 'api/chaletApi';

const CustomCard = styled(Card)(({ theme }) => ({
  margin: 5,
  borderRadius: '7px',
  cursor: 'pointer',
}));

type OrderCardProps = {
  order: Order;
  handleOpen?: () => void;
};

export const OrderCard: React.FC<OrderCardProps> = ({ order, handleOpen }) => {
  const { data: currentOrderProcessStep } =
    chaletApi.useGetCurrentOrderProcessStepQuery(
      order.currentOrderProcessStepId,
    );

  return (
    <CustomCard elevation={4} onClick={handleOpen}>
      <CardContent style={{ padding: '10px', position: 'relative' }}>
        <Box>
          <Typography flex={1} variant="body2">
            {formatDateWithTime(order.createdAt)}
          </Typography>
          <Box
            id="orderid"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 2,
              paddingRight: 2,
              backgroundColor: '#EF3D32',
              borderRadius: '0px  7px',
            }}
          >
            <Typography color={'#FFFFFF'} variant="body2">
              {order.id}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <Box display={'flex'}>
            <Typography flex={1} fontWeight={'bold'}>
              Productos:{' '}
            </Typography>
            <Typography flex={0}>
              {toCurrencyFormat(order.productsTotal)}{' '}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography flex={1} fontWeight={'bold'}>
              Delivery:{' '}
            </Typography>
            <Typography flex={0}>
              {toCurrencyFormat(order.deliveryPrice)}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography flex={1} fontWeight={'bold'}>
              Total:{' '}
            </Typography>
            <Typography flex={0}>{toCurrencyFormat(order.total)}</Typography>
          </Box>
        </Box>
        <Box>
          {order.isActive && currentOrderProcessStep && (
            <OrderProcessStepper
              steps={6}
              currentStep={currentOrderProcessStep.correlative}
              stepName={currentOrderProcessStep.name}
              lastUpdatedTime={order.updatedAt}
              icon={currentOrderProcessStep.icon}
            />
          )}
          {order.isCompleted && (
            <Typography variant="subtitle2" color="#0e766e">
              ● Entregado {formatTimeString(order.updatedAt)}
            </Typography>
          )}
          {order.isCanceled && (
            <Typography variant="subtitle2" color="#C0392B">
              ● Cancelado {formatTimeString(order.updatedAt)}
            </Typography>
          )}
        </Box>
      </CardContent>
    </CustomCard>
  );
};
