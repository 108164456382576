import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ShopCartItem } from 'types/ShopCart';

type CartState = {
  shopCarts: ShopCartItem[];
};

const cartPersistConfig = {
  key: 'cart',
  storage: storage,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: { shopCarts: [] } as CartState,
  reducers: {
    addToCart: (state, action: PayloadAction<ShopCartItem>) => {
      const {
        shopId,
        products,
        shopName,
        shopImage,
        shopLocation,
        shopDeliveryTime,
      } = action.payload;
      const newCart = [...state.shopCarts];
      const cartIndex = newCart.findIndex(
        (cartItem) => cartItem.shopId === shopId,
      );

      if (cartIndex !== -1) {
        products.forEach((product) => {
          const existingProductIndex = newCart[cartIndex].products.findIndex(
            (cartProduct) =>
              cartProduct.uniqueProductId === product.uniqueProductId,
          );

          if (existingProductIndex === -1) {
            newCart[cartIndex].products.push(product);
          }
        });
      } else {
        newCart.push({
          shopId,
          shopName,
          shopImage,
          shopLocation,
          shopDeliveryTime,
          products,
        });
      }

      state.shopCarts = newCart;
    },

    removeFromCart: (
      state,
      action: PayloadAction<{ shopId: number; uniqueProductId: number }>,
    ) => {
      const { shopId, uniqueProductId } = action.payload;
      const newCart = [...state.shopCarts];
      const cartIndex = newCart.findIndex(
        (cartItem) => cartItem.shopId === shopId,
      );

      if (cartIndex !== -1) {
        newCart[cartIndex].products = newCart[cartIndex].products.filter(
          (product) => product.uniqueProductId !== uniqueProductId,
        );

        if (newCart[cartIndex].products.length === 0) {
          newCart.splice(cartIndex, 1);
        }
      }

      state.shopCarts = newCart;
    },

    updateQuantity: (
      state,
      action: PayloadAction<{
        shopId: number;
        productId: number;
        quantity: number;
      }>,
    ) => {
      const { shopId, productId, quantity } = action.payload;
      const newCart = [...state.shopCarts];
      const cartIndex = newCart.findIndex(
        (cartItem) => cartItem.shopId === shopId,
      );

      if (cartIndex !== -1) {
        const productExists = newCart[cartIndex].products.find(
          (product) => product.productId === productId,
        );
        if (productExists) {
          productExists.quantity = quantity;
        }
      }

      state.shopCarts = newCart;
    },

    clearCart: (state, action: PayloadAction<number>) => {
      const shopId = action.payload;
      const newCart = [...state.shopCarts];
      const cartIndex = newCart.findIndex(
        (cartItem) => cartItem.shopId === shopId,
      );

      if (cartIndex !== -1) {
        newCart.splice(cartIndex, 1);
      }

      state.shopCarts = newCart;
    },

    clearAllCarts: (state) => {
      state.shopCarts = [];
    },
  },
});

const persistedCartSlice = persistReducer(cartPersistConfig, cartSlice.reducer);

export const {
  addToCart,
  removeFromCart,
  updateQuantity,
  clearCart,
  clearAllCarts,
} = cartSlice.actions;

export default persistedCartSlice;
