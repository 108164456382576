import { Box } from '@mui/material';
import RootProvider from './providers/RootProvider';
import { ThemeConfig } from './ui/config/theme.config';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './routes';

function App() {
  return (
    <Box display="flex">
      <RootProvider>
        <ThemeConfig>
          <BrowserRouter>
            <Box display="flex" flexDirection="column" flex={1}>
              <AppRouter />
            </Box>
          </BrowserRouter>
        </ThemeConfig>
      </RootProvider>
    </Box>
  );
}

export default App;
