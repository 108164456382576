import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import chaletApi from 'api/chaletApi';
import toast from 'react-hot-toast';
import shopImage from '../../../assets/img-shop-bike.webp';
import { useAppSelector } from 'redux/hook';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  name: yup.string().required('El nombre del negocio es obligatorio'),
  address: yup.string().required('La dirección del negocio es obligatoria'),
  type: yup.string().required('El tipo de negocio es obligatorio'),
  userName: yup.string().required('El nombre es obligatorio'),
  userPhone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(9, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  userEmail: yup
    .string()
    .email('Ingrese un correo electrónico válido')
    .required('El correo electrónico es obligatorio'),
});

const MaskNumber = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1');
};

type FormData = {
  name: string;
  address: string;
  type: string;

  userName: string;
  userPhone: string;
  userEmail: string;
};

export const ShopRegisterForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [registerShopWithUser, registerResult] =
    chaletApi.useRegisterShopWithUserMutation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoggedIn } = useAppSelector((state) => state.userAuthSlice);
  const { user } = useAppSelector((state) => state.user);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      address: '',
      type: '',
      userName: '',
      userPhone: '',
      userEmail: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (user) {
      setValue('userName', user.name || '');
      setValue('userPhone', MaskNumber(user.phone) || '');
      setValue('userEmail', user.email || '');
    }
  }, [user, setValue]);

  const onSubmit = (data: FormData) => {
    const { name, address, userEmail, userName, userPhone, type } = data;
    const user = { name: userName, phone: userPhone, email: userEmail };
    const shop = { name, address, type: type as any };
    console.log('Request: ', { user, shop });
    registerShopWithUser({ user, shop })
      .unwrap()
      .then(
        () => {
          toast.success(
            'Solicitud enviada con exito. \nEn breve nos pondremos en contacto contigo.',
          );
          // Resetear los valores del formulario después del registro exitoso
          reset();
        },
        (error) => {
          console.log(error);
          if ('data' in error && error.data.error) {
            toast.error(error.data.error);
          }
        },
      );
  };

  useEffect(() => {
    if (user && user.Shops && user.Shops.length > 0) {
      toast.success('Ya tienes un negocio registrado');
      navigate('/perfil');
    }
  }, [user]);

  let cardMessage =
    'Obten acceso a la plataforma de última generación para administrar tu negocio, incrementar tus ventas y llegar a más clientes.';
  let cartTitle = '¡Registrate ahora!';
  if (registerResult.isSuccess) {
    cardMessage =
      'Solicitud enviada con exito. \nEn breve nos pondremos en contacto contigo.';
    cartTitle = 'Solicitud enviada';
  }
  return (
    <Grid
      container
      sx={{ backgroundColor: 'background.default' }}
      justifyContent="center"
    >
      <Grid
        item
        container
        sx={{
          pt: isMobile ? 0 : 4,
          pb: isMobile ? 0 : 4,
          maxWidth: 'lg',
        }}
      >
        <Grid item xs={12} md={5}>
          <Grid item justifyContent="center" display="flex" flex={1}>
            <img src={shopImage} width="90%" style={{ objectFit: 'cover' }} />
          </Grid>
          <Paper sx={{ backgroundColor: 'primary.main', p: 2, m: 2 }}>
            <Typography variant="h6" color="white.main">
              {cartTitle}
            </Typography>
            <Typography variant="body1" color="white.main">
              {cardMessage}
            </Typography>
          </Paper>
        </Grid>
        <Grid item sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Paper
            sx={{
              padding: 4,
              borderRadius: 2,
              width: isMobile ? '100%' : 500,
              alignSelf: 'center',
              justifySelf: 'center',
            }}
            elevation={4}
          >
            <Typography sx={{ mt: 1, mb: 1 }} variant="h4" textAlign="center">
              Registro de Negocio
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombre del Negocio"
                    fullWidth
                    sx={{ mt: 2, mb: 1.5 }}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />

              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Dirección del Negocio"
                    fullWidth
                    sx={{ mb: 1.5 }}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    name={field.name}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="food"
                      control={<Radio />}
                      label="Restaurante"
                    />
                    <FormControlLabel
                      value="market"
                      control={<Radio />}
                      label="Supermercado / Tienda"
                    />
                    <FormControlLabel
                      value="shopping"
                      control={<Radio />}
                      label="Tienda de Ropa / Zapatos / Accesorios / Otros"
                    />
                  </RadioGroup>
                )}
              />
              {errors.type && (
                <Typography variant="body2" color="error">
                  {errors.type.message}
                </Typography>
              )}

              <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                Contacto
              </Typography>
              <Grid item container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Nombre"
                        fullWidth
                        sx={{ mb: 1.5 }}
                        error={!!errors.userName}
                        helperText={errors.userName?.message}
                        type="tel"
                        inputMode="numeric"
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="userPhone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Número celular"
                        fullWidth
                        sx={{ mb: 1.5 }}
                        error={!!errors.userPhone}
                        helperText={errors.userPhone?.message}
                        onChange={(event) => {
                          const { value } = event.target;
                          field.onChange(MaskNumber(value));
                        }}
                        disabled={isLoggedIn}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="userEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Correo electrónico"
                        fullWidth
                        sx={{ mb: 1.5 }}
                        error={!!errors.userEmail}
                        helperText={
                          errors.userEmail ? errors.userEmail.message : ''
                        }
                        disabled={user?.email !== null && isLoggedIn === true}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mb: 3 }}
                size="large"
              >
                Registrar Negocio
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
